/* TelaVendas.css */

.cardTextNome {
  font-weight: bold;
  font-size: 16;
  margin-bottom: 5;
  color: #000;
}

.cardText {
  font-weight: 'bold';
  font-size: 10;
  margin-bottom: 5;
  color: #000;
}


.cardT {
  margin-top: 10px;
  border-width: 1px;
  border-radius: 10px;
  border: 1px solid #ccc;
  padding: 15px;
  margin-bottom: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: transform 0.2s;

}

.cardOperador {
  border-width: 1;
  border-color: '#ccc';
  border-radius: 10;
  padding: 10;
  margin-bottom: 10;
  width: '100%';
  background-color: '#9a9e98';
}