.div-principal{
 display: flex;
 flex-direction: column;
 align-items: center;
}


.input-estilizado{
    text-align: center;
    height: 40px;
    padding: 0;
}

.botoes-quantidade{
    background-color: black;
    color: #fff;
    width: 40px;
    height:40px;;
}

.titulo{
    font-size: 14px;
    font-weight: bold;
}

.button-container {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
  }

/* Botão Excluir Item */
.excluir-button {
    background-color: black;
    color: white;
    padding: 10px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    width: 100%;
    margin-bottom: 10px;
    cursor: pointer;
  }
  
  .excluir-button .trash-icon {
    margin-left: 5px; /* Espaço entre o texto e o ícone */
  }
  
  /* Botões Cancelar e Confirmar */
  .action-buttons {
    display: flex;
    justify-content: space-between;
    gap: 10px;
  }
  
  .cancel-button {
    background-color: red;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 14px;
    font-weight: bold;
    flex: 1;
    cursor: pointer;
  }
  
  .confirm-button {
    background-color: green;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 14px;
    font-weight: bold;
    flex: 1;
    cursor: pointer;
  }
  