.page-header {
  background-color: #000000;
  font-size: 20px;
  margin: 0;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 99;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between; /* Certifique-se de que o espaço é distribuído corretamente */
  padding: 0 10px; /* Adiciona espaço nas laterais */
}

.left {
  color: white;
  font-size: 20px;
  font-weight: bold;
  text-align: left;
  flex: 1;
}

.center {
  color: white;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  flex: 1;
}

.right {
  color: white;
  font-size: 20px;
  font-weight: bold;
  text-align: right;
  flex: 1;
  padding-right: 20px; /* Adiciona espaço à direita */
}
