/* FinalizarVenda.css */

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background-color: #ffffff;
  }
  
  .modal-header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 10px;
    background-color: #f1f1f1;
    border-bottom: 1px solid #ccc;
 
  }
  
  .close-button {
    background: none;
    border: none;
    font-size: 1.5em;
    cursor: pointer;
  }
  
  .container-buttons {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .row {
    display: flex;
    justify-content: center;
    gap: 10px;
  }
  
  .button {
    width: 280px;
    height: 70px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1em;
    font-weight: bold;
    color: white;
    cursor: pointer;
  }
  
  .button-primary {
    background-color: #4682b4;
  }
  
  .button-success {
    background-color: #6ef018;
  }
  
  .button-warning {
    background-color: #ffa500;
  }
  
  .button-danger {
    background-color: #ff6347;
  }
  .button-purple {
    background-color: #6a0980;
  }


  
  .button-dark {
    background-color: #333;
  }
  
  .button-disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
  .card_totais {
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 10px;
    background-color: #f9f9f9;
    width: 100%;
    margin-top: 10px;
  }
  