/* Pesquisar.css */

.container-pesquisar {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 20px;
  background-color: #fff;
  font-family: Arial, sans-serif;
  height: 70vh;
}

.close-button {
  background: none;
  border: none;
  font-size: 16px;
  color: #007bff;
  cursor: pointer;
}

.search-container {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  margin-top: 10px;
  width: 100%;
}

.input {
  flex: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  margin-right: 10px;
}

.search-button {
  padding: 10px 15px;
  background-color: #000;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.search-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.scroll-container {
  width: 100%;
  overflow-y: auto; /* Adiciona a rolagem vertical */
  padding-right: 10px; /* Evita que a barra de rolagem sobreponha o conteúdo */
}

.product-list {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 20px; /* Espaço extra na parte inferior para a rolagem */
}

.card {
  width: 100%;
  max-width: 95%;
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 5px;
  margin-bottom: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: transform 0.2s;
}

.card:hover {
  transform: scale(1.02);
}

.card-text {
  font-size: 16px;
  font-weight: bold;
  margin: 5px 0;
  color: #333;
}

.card-text-ean {
  font-size: 12px;
  color: #666;
}

.empty-list-text {
  margin-top: 20px;
  font-size: 16px;
  color: #999;
}

.loading {
  font-size: 16px;
  color: #007bff;
}
